import React from "react"
import { graphql, Link } from "gatsby"
import classnames from "classnames"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image } from "../components/Core"
import { CallToAction } from "../components/CTA"

const CommunityQueryPage = ({ data, pageContext, location }) => {
  let post = data.allUniquePagesJson.nodes[0]
  let { metaTitle, metaDescription, language } = pageContext

  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <div className="joshua-tree-content community-query-page">
        {/* Top Section */}
        <div className="columns top-section community__heading-section">
          <div className="column">
            <div className="columns">
              <div className="column is-3" />
              <Text as="h1" className="mb-2" text={post.heading} />
            </div>
            <div className="columns">
              <div className="column is-4" />
              <div className="column">
                <Text as="p" text={post.blurb} />
              </div>
              <div className="column is-4" />
            </div>
          </div>
        </div>

        <section className="community">
          {/* <Grid pages={post.pages} /> */}
          <ThreeImageGrid pages={post.pages} gridSize={post.pages.length} />
        </section>

        <CallToAction
          colorBack
          heading={post.getStarted.heading}
          blurb={post.getStarted.blurb}
          buttons={post.getStarted.buttons}
        />
      </div>
    </Layout>
  )
}

function Grid(props) {
  // Split into rows of 2
  const chunk_size = 2
  const arr = props.pages
  const groups = arr
    .map((e, i) => (i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null))
    .filter(e => e)

  return groups.map((row, i) => (
    <GridRow key={`community-row-${i}`} items={row} />
  ))
}

function GridRow(props) {
  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const { page } = item
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-2" />}
            <div
              className={`column community-item ${
                itemLen === i + 1 ? "is-9" : "is-9"
              }`}>
              <Link className="hover-link" to={page.href}>
                <Nuvo useAR publicId={page.img} className="meet-our-doctor" />
              </Link>
              <div className="community-subtext">
                <Link className="heading-link hover-link" to={page.href}>
                  <Text as="h3" text={page.heading} />
                </Link>
                <Link
                  className="standard-button contained"
                  style={{ marginTop: 0 }}
                  to={page.href}>
                  Learn More
                </Link>
              </div>
            </div>
            <div className="column is-2" />
          </>
        )
      })}
    </div>
  )
}

function ThreeImageGrid({ pages, gridSize }) {
  const gridClass = classnames({
    "community-two-grid": gridSize === 2,
    "community-three-grid": !gridSize || gridSize === 3 || gridSize > 4,
    "community-4-grid": gridSize === 4
  })
  return (
    <div className="columns is-desktop community-row">
      <div className="column is-2" />
      <div className={`column community-item ${gridClass}`}>
        {pages &&
          pages.map((page, i) => {
            if (page.page.href) {
              return (
                <div key={i}>
                  <Link
                    className="hover-link"
                    to={page.page.href}
                    style={{ display: "block" }}>
                    <Image
                      useAR={false}
                      publicId={page.page.img}
                      className="meet-our-doctor"
                      aspectRatio="16:9"
                      responsive
                      gravity="faces"
                      width="auto"
                      crop="fill"
                    />
                  </Link>
                  <div className="community-subtext">
                    <Link
                      className="heading-link hover-link"
                      to={page.page.href}>
                      <Text as="h3" text={page.page.heading} />
                    </Link>
                    <Link
                      className="standard-button contained"
                      style={{ marginTop: 10 }}
                      to={page.page.href}>
                      Learn More
                    </Link>
                  </div>
                </div>
              )
            }
            return (
              <div key={i}>
                <Image
                  useAR
                  publicId={page.page.img}
                  className="meet-our-doctor"
                  aspectRatio="16:9"
                  responsive
                  width="auto"
                  crop="fill"
                />
                <div className="community-subtext">
                  <Text as="h3" text={page.page.heading} />
                </div>
              </div>
            )
          })}
      </div>
      <div className="column is-2" />
    </div>
  )
}

export const pageQuery = graphql`
  query CommunityQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        getStarted {
          heading
          blurb
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
        }
        pages {
          page {
            heading
            img
            href
          }
        }
      }
    }
  }
`

export default CommunityQueryPage
